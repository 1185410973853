import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../../layouts";
import FlexibleContent from "../../components/FlexibleContent";
import ColorBlockGrid from "../../components/ColorBlockGrid";
import Services from "../../components/Services";
import SEO from '../../components/seo/SEO';
import { pageTitle } from "../../utils/helpers";
import { useSiteMetadata } from "../../hooks/use-site-metadata";

export default function Approach( props ) {

    const site = useSiteMetadata();
    const currentPage = props.data.wordpressPage;
    

    const currentPageTitle = (currentPage.yoast_meta.yoast_wpseo_title) ? currentPage.yoast_meta.yoast_wpseo_title : pageTitle({
      site: site.title,
      page: currentPage
    });

    return (
      <Layout>
        <Helmet bodyAttributes={{ id: `page-${currentPage.slug}` }}>
          <title>
            {currentPageTitle}
          </title>
        </Helmet>
        <SEO node={currentPage} site={site} />
        <FlexibleContent currentPage={currentPage} />
        <ColorBlockGrid currentPage={currentPage} />
        <Services node={currentPage} />
      </Layout>
    );
  
}

export const pageQuery = graphql`
  {
    wordpressPage(title: { eq: "Approach" }) {
      title
      slug
      ...Hero
      ...Info
      ...Grid
      ...Interstitial
      ...ColorBlockGrid
      ...Services
      ...SEO
    }
  }
`;
