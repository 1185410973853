import React from "react"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

export default ({ currentPage }) => {

	return (
		<section className="section-grid-8 color-block-grid">
		{
			currentPage.acf.color_block_grid.map( (thisItem, i) => {
				return[
					<div key={i} className="item" style={{backgroundColor: thisItem.background_color}}>
						<div className="item--content">
							<h5 className="item--title" style={{color: thisItem.heading_color}} dangerouslySetInnerHTML={{__html: thisItem.heading}} />
							<span style={{color: thisItem.text_color}} dangerouslySetInnerHTML={{__html: thisItem.text}} />
						</div>
					</div>,
					<div key={i+8} className="item">
						<Img fluid={thisItem.background_image.localFile.childCloudinaryAsset.fluid} />
						<div className="item--content">
							&nbsp;
						</div>
					</div>
				]
			})
		}
	</section>
)}

export const colorBlockQuery = graphql`
	fragment ColorBlockGrid on wordpress__PAGE {
		acf{
			color_block_grid{
				heading
				heading_color
				text
				text_color
				background_color
				background_image{
					localFile{
						childCloudinaryAsset{
							fluid(maxWidth:800){
								...CloudinaryAssetFluid
							}
						}
					}
					alt_text
				}
			}
		}
	}
`
