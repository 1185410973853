import React from "react"
import { graphql } from 'gatsby'

export default ({ node }) => {

	return (
    <section id="section-services" className="section-body">
      <div className="content">
        <div className="content--main">
          <h4 className="grid-title">Services</h4>
          <div className="grid-services">
          {
            node.acf.services.map( (thisItem, i) => {
              return(
                <div key={i} className="item">
                {thisItem.image !== false &&
                  <img srcSet={thisItem.image.localFile.childCloudinaryAsset.fluid.srcSet} src={thisItem.image.localFile.childCloudinaryAsset.fluid.src} sizes={thisItem.image.localFile.childCloudinaryAsset.fluid.sizes} alt={thisItem.image.alt_text} height="120" />
                }
                  <h5 className="item--title" dangerouslySetInnerHTML={{__html: thisItem.title}} />
                  <span dangerouslySetInnerHTML={{__html: thisItem.text}} />
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
    </section>
)}

export const servicesQuery = graphql`
  	fragment Services on wordpress__PAGE {
  		acf{
				services{
	        image{
	          localFile{
	            childCloudinaryAsset{
	              fluid{
	                ...CloudinaryAssetFluid
	              }
	            }
	          }
	          alt_text
	        }
	        title
	        text
	      }
      }
    }
`
